import { Controller } from "stimulus"
import EventHelper from "../helpers/event_helper.js"
import RequestHelper from "../helpers/request_helper.js"

export default class extends Controller {
  static get targets() {
    return ['modal', 'image', 'previewImage', 'edit', 'previewVideoModal', 'previewVideo', 'previewGif', 'preview', 'upload', 'remove', 'error', 'replacementNode', 'dropzone'];
  }

  initialize() {
    EventHelper.forwardJqueryEvent(this.modalTarget, 'shown.bs.modal');
    EventHelper.forwardJqueryEvent(this.modalTarget, 'hidden.bs.modal');
    this.canvasWidth = parseInt(this.element.getAttribute("data-width"));
    this.canvasHeight = parseInt(this.element.getAttribute("data-height"));
    this.requestHelper = RequestHelper;
    this.fillColor = this.element.getAttribute("data-fill-color") || "#fff";
    this.aspectRatio = parseFloat(this.element.getAttribute("data-aspect-ratio")) || 1;
    this.circular = this.element.getAttribute("data-circular") === "true";
    this.createAndBindDropzone(this);
  }

  // When using Circular cropping we need to enforce PNG
  getUploadType() {
    if (this.circular) {
      return "image/png";
    } else {
      return this.uploadType;
    }
  }

  createAndBindDropzone(controller) {
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      this.dropzoneTarget.addEventListener(eventName, (event) => {
        event.preventDefault();
        event.stopPropagation();
      });
    });
    ['dragenter', 'dragover'].forEach(eventName => {
      this.dropzoneTarget.addEventListener(eventName, () => {
        this.dropzoneTarget.classList.add('highlight');
      });
    });
    ['dragleave', 'drop'].forEach(eventName => {
      this.dropzoneTarget.addEventListener(eventName, () => {
        this.dropzoneTarget.classList.remove('highlight');
      });
    });
    this.dropzoneTarget.addEventListener('drop', this.loadMediaForCropping.bind(this), false)
  }

  rotateImage() {
    this.cropper.rotate(90);

    const canvasData = this.cropper.getCanvasData();
    const newAspectRatio = canvasData.width / canvasData.height;

    this.cropper.setAspectRatio(newAspectRatio);
  }

  showModal(modal) {
    [this.errorTarget,
      this.editTarget,
      this.previewTarget,
      this.previewVideoModalTarget,
      this.uploadTarget,
      this.removeTarget].every(otherModal => $(otherModal).hide());

    $(this.modalTarget).modal({backdrop: 'static'});
    $(modal).show();
  }

  loadMediaForCropping(event) {
    const files = event.currentTarget.files || event.dataTransfer.files;
    this.dataset = event.currentTarget.dataset;
    this.uploadType = files[0].type;

    (['video/mp4', 'image/gif'].includes(this.uploadType)) ?
      this.loadAnimatedMediaForCropping(event) :
      this.loadImageForCropping(event);
  }

  loadImageForCropping(event) {
    const files = event.currentTarget.files || event.dataTransfer.files;
    this.uploadType = files[0].type;

    let done = url => {
      event.currentTarget.value = '';
      this.imageTarget.src = url;
      this.showModal(this.editTarget);
    }

    let reader, file;

    if (files && files.length > 0) {
      file = files[0];
      if (URL) {
        done(URL.createObjectURL(file));
      } else if (FileReader) {
        reader = new FileReader();
        reader.onload = function (e) {
          done(reader.result);
        };
        reader.readAsDataURL(file);
      }
    }
  }

  loadAnimatedMediaForCropping(event) {
    this.showModal(this.previewVideoModalTarget);
    if (event.currentTarget.files) {
      this.animatedFile = event.currentTarget.files[0];
    } else {
      this.animatedFile = event.dataTransfer.files[0];
    }
    if (this.uploadType === 'video/mp4') {
      this.previewGifTarget.classList.add('d-none');
      this.previewVideoTarget.classList.remove('d-none');
      this.previewVideoTarget.src = URL.createObjectURL(this.animatedFile);
      this.previewVideoTarget.load();
    } else {
      this.previewGifTarget.classList.remove('d-none');
      this.previewVideoTarget.classList.add('d-none');
      this.previewGifTarget.src = URL.createObjectURL(this.animatedFile);
    }
  }

  previewCroppedImage() {
    this.showModal(this.previewTarget);
    this.previewImageTarget.src = this.getCanvas().toDataURL(this.getUploadType(), 0.9);
  }

  getCanvas() {
    let canvas;

    if (this.cropper) {
      if (this.canvasWidth && this.canvasHeight) {
        canvas = this.cropper.getCroppedCanvas({
          width: this.canvasWidth,
          height: this.canvasHeight,
          fillColor: this.fillColor
        });
      } else {
        canvas = this.cropper.getCroppedCanvas({
          fillColor: this.fillColor
        });
      }

      if (this.circular) {
        return this.getCircularCanvas(canvas);
      }
    }

    return canvas;
  }

  getCircularCanvas(sourceCanvas) {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const width = sourceCanvas.width;
    const height = sourceCanvas.height;

    canvas.width = width;
    canvas.height = height;

    context.imageSmoothingEnabled = true;
    context.drawImage(sourceCanvas, 0, 0, width, height);
    context.globalCompositeOperation = 'destination-in';

    context.beginPath();
    context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
    context.fill();

    return canvas;
  }

  backToEditImage() {
    this.showModal(this.editTarget);
  }

  persistAnimatedMedia() {
    const context = this;
    this.showModal(this.uploadTarget);
    const rootElement = document.querySelector(this.dataset.replacetargetselector);
    let params = RequestHelper.defaultPostOptions();
    let formData = new FormData();
    formData.append(context.dataset.imageName, this.animatedFile);
    formData.append('_method', context.dataset.method);
    fetch(context.dataset.path, Object.assign(params, { "body": formData })).
      then((response) => {
        if (!response.ok) {
          throw new Error();
        }
        return response.text();
      }).
      then(json => JSON.parse(json).html).
      then(html => {
        rootElement.parentNode.replaceWith(context.requestHelper.htmlNodeFromString(html), rootElement);
        rootElement.classList.remove("missing");
        document.getElementById(`replacement-node-${context.dataset.imageName}`).remove();
      }).
      then(() => {
        $(context.modalTarget).modal('hide');
      }).catch(err => context.showModal(context.errorTarget))
  }

  persistCroppedImage() {
    const context = this;
    this.showModal(this.uploadTarget);
    const rootElement = document.querySelector(this.dataset.replacetargetselector);
    this.getCanvas().toBlob((blob) => {
      let params = RequestHelper.defaultPostOptions();
      let formData = new FormData();
      formData.append(context.dataset.imageName, blob);
      formData.append('_method', context.dataset.method);
      fetch(context.dataset.path, Object.assign(params, { "body": formData })).
        then((response) => {
          if (!response.ok) { throw new Error(); }
          return response.text();
        }).
        then(json => JSON.parse(json).html).
        then(html => {
          rootElement.parentNode.replaceWith(context.requestHelper.htmlNodeFromString(html), rootElement);
          rootElement.classList.remove("missing");
          document.getElementById(`replacement-node-${context.dataset.imageName}`).remove();
        }).
        then(() => {
          $(context.modalTarget).modal('hide');
        }).catch(err => context.showModal(context.errorTarget))
    }, this.getUploadType(), 0.9);
  }

  deleteImage(event) {
    event.preventDefault();
    const context = this;
    this.showModal(this.removeTarget);
    const $anchor = $(event.target).closest('a');
    const url = $anchor.attr('href');
    const imageName = $anchor.data("imageName");
    let formData = new FormData();
    formData.append('_method', 'patch');
    if (imageName) {
      formData.append(`${imageName}_delete`, "1");
    }
    fetch(url, Object.assign(RequestHelper.defaultPostOptions(), { "body": formData })).
      then(response => response.text()).
      then(json => JSON.parse(json).html).
      then(html => {
        context.replacementNodeTarget.parentNode.replaceWith(context.requestHelper.htmlNodeFromString(html), context.replacementNodeTarget);
        document.getElementById(`replacement-control-node-${imageName}`).remove();
      }).
      then(() => {
        $(context.modalTarget).modal('hide');
      })
  }

  initializeCropper() {
    if (this.canvasHeight && this.canvasWidth) {
      let autoCropArea
      if (this.imageTarget.naturalWidth == undefined || this.imageTarget.naturalHeight == undefined || this.imageTarget.naturalWidth <= 450 || this.imageTarget.naturalHeight <= 450) {
        autoCropArea = 1
      } else {
        autoCropArea = 450 / Math.min(this.imageTarget.naturalWidth, this.imageTarget.naturalHeight)
      }
      this.cropper = new Cropper(this.imageTarget, {
        aspectRatio: this.aspectRatio,
        viewMode: 0,
        imageSmoothingEnabled: true,
        imageSmoothingQuality: 'high',
        guides: false,
        autoCropArea: autoCropArea
      });
    } else {
      this.cropper = new Cropper(this.imageTarget, {
        viewMode: 0,
        guides: false
      });
    }
  }

  destroyCropper() {
    this.cropper.destroy();
    this.cropper = null;
  }
}
